
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react';
import { InferGetStaticPropsType } from 'next';
import { getPageStaticProps } from '@/utils/slug';
import SiteLayout from '@/components/layout/site';
import Fasce from '@/components/layout/site/fasce';
import { PageType } from '@/utils/router';

const HomePage = ({ data }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const page = data?.pages?.nodes?.[0];
	return <SiteLayout pageType={PageType.POST_CATEGORY} data={data} translations={page?.translations} seo={page?.seo} language={page?.language}>
    <Fasce fasce={page?.template?.fasce} translations={page?.translations}></Fasce>
	</SiteLayout>
}

export default HomePage;

 const _getStaticProps = getPageStaticProps;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  